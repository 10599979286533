<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-3xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<div class="space-y-1">
								<p class="text-lg text-left text-gray-700">Please use a VPN as a resolution to any buffering/freezing that you may experience.</p>
								<p class="text-lg text-left text-gray-700">
									Internet service providers are throttling and slowing down connections to our servers which leads to buffering and freezing issues you’re
									experiencing. This isn’t something that can be fixed from our end and can happen at anytime even if it worked perfectly for you without a VPN in
									the past. You can rest assured that it is our mission to provide you with the best service available and we continuously keep our servers in tip
									top shape to ensure all our customers receive only the best.
								</p>
							</div>
							<p>Please read and follow the information below for resolution steps.</p>
							<p class="font-semibold text-lg">Commonly Asked Questions:</p>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: What is a VPN?</p>
								<p>
									A: A VPN disguises your internet network from your internet provider in order to stop them from seeing what you use your internet for and how
									you choose to use your internet.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: I called my internet provider and they told me that they do not throttle my internet or slow down my speeds?</p>
								<p>
									A: we can assure you that after providing support for many customers we can determine that internet providers do not like to advertise or admit
									to their customers that they throttle their speeds. After connecting a VPN the issue almost magically disappears which clearly displays that the
									internet provider is throttling the speed.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q:But my HULU, Amazon, Netflix accounts work just fine without buffering?</p>
								<p>
									A: This is specific to our servers and a VPN is the only solution to stop your internet provider from slowing down your speeds when using our
									service.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: What VPN’s do you recommend?</p>
								<p>A: Our Recommended VPN providers are:</p>
								<ul class="font-bold">
									<li>-Ipvanish</li>
									<li><a href="https://surfshark.com/">Surfshark </a></li>
									<li>-Nord VPN</li>
								</ul>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: How can I install a VPN?</p>
								<p>A: Please visit our recommended VPN provider’s website for detailed installation instructions guides for all of your devices.</p>
								<p>Additional how-to installation videos are available on YouTube.</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: But I already have a VPN, why do I need to switch?</p>
								<p>
									A: Not all VPN providers are created equal. Please choose a VPN from our recommended VPN providers listed above. These VPN's have been
									thoroughly tested for best results.
								</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold">Q: I’m already using a VPN and still experiencing issues, why?</p>
								<p>
									A: If you are already using one of our recommended VPN providers and still experiencing buffering, please try changing the server location
									within the VPN.
								</p>
								<p>For instructions on how to switch the server location, please visit your VPN's website.</p>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: Should I choose the fastest server on my VPN?</p>
								<p>A: Choosing the fastest server option on your VPN will not always provide the best results.</p>
								<p>
									Please try <span class="font-semibold">multiple locations</span> until you're able to find a server location that provides you a buffer-free
									experience.
								</p>
								<p>You may need to cycle throughout all of the VPN server locations to find the best one for you.</p>
								<p>Please visit your VPN provider's website for more information on how to change the server location.</p>
							</div>
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: Which VPN server locations do you recommend?</p>
								<p>
									A: VPN services provide us multiple server locations options to choose from. The best server location for you will vary. The VPN server location
									that worked perfectly for you today may not necessarily work the best for you tomorrow due to congestion. This is one of the reasons that VPN
									services provide several locations. Switching locations only takes a few short seconds. Before you begin, we recommend clearing cache or
									rebooting your device for best results.
								</p>
								<p>Some of our suggested VPN server locations are:</p>
								<ul>
									<li>-USA</li>
									<li>-Canada</li>
									<li>-Denmark</li>
									<li>-Sweden</li>
									<li>-Switzerland</li>
									<li>-Norway</li>
									<li>-Latvia</li>
									<li>-Finland</li>
								</ul>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="font-semibold text-lg">Q: Sometimes my service works better without my VPN, why?</p>
								<p>
									A: Yes it's important to note that Internet providers may only throttle at certain times or during popular events. VPN's are only required when
									you're <span class="font-semibold">actively</span> being throttled. There may be situations where you will need to disconnect your VPN for best
									results. We realize this can be confusing but these are tested and proven methods so please try these troubleshooting tips before contacting us
									for any additional support.
								</p>
							</div>
							<hr />
							<div class="text-center py-10">
								<router-link :to="{ name: 'Home' }" class="py-4 px-8 text-lg border-2 border-green-500 rounded-full hover:bg-green-500 hover:text-white"
									>Help Center</router-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'VPNInformation',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
